import { defineDocument } from 'sanity-typed-queries'

export const okendoInfo = defineDocument(
  'okendoInfo',
  {
    entityId: { type: 'string' },
    title: { type: 'string' },
    stars: { type: 'number' },
    totalNumberOfReviews: { type: 'number' },
  },
  []
)
