import React, { useContext } from 'react'
import { BackgroundColours } from '@heights/heights-utils'
import { Hero, HeroStyle } from '@heights/heights-ui-components'
import { get, Box, useThemeUI } from 'theme-ui'
import { useTransformContentfulCtaAction } from '@/hooks/useCtaAction'
import { useData, useOkendoData } from '@/hooks'
import compact from 'just-compact'
import { LayoutContext } from '@/context'
import { RichTextContent } from '@/components/RichTextContent'
import { transformImage } from '@/utils/contentful'
import type { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { hero, heroSection } from '@/schemas'
import { DataSourceType } from '@/hooks/useData'
import { asResolverQuery } from '@/utils/groq'
import { decodeShopifyId } from '@/utils/shopify'

type MediaOverlay = 'none' | 'light' | 'medium' | 'dark'
type Props =
  | {
      heroData: SingleComponentPickerItem
    }
  | {
      heroSectionData: SingleComponentPickerItem
    }

const imageAspectRatios: Partial<Record<HeroStyle, number>> = {
  [HeroStyle.FULLSCREEN_CENTER_ALIGNED]: 16 / 9,
  [HeroStyle.FULLSCREEN_LEFT_ALIGNED]: 16 / 9,
  [HeroStyle.STACKED_CENTER_ALIGNED]: 4 / 3,
  [HeroStyle.SHORT_LEFT_ALIGNED]: 1,
  [HeroStyle.SHORT_CENTER_ALIGNED]: 1,
  [HeroStyle.SHORT_LEFT_ALIGNED_WITH_IMAGE]: 1,
  [HeroStyle.BIG_WITH_BOTTOM_IMAGE]: 1,
  [HeroStyle.BIG_WITH_CENTERED_IMAGE]: 1,
}

const mobileImageAspectRatios: Partial<Record<HeroStyle, number>> = {
  [HeroStyle.FULLSCREEN_CENTER_ALIGNED]: 9 / 16,
  [HeroStyle.FULLSCREEN_LEFT_ALIGNED]: 9 / 16,
  [HeroStyle.STACKED_CENTER_ALIGNED]: 4 / 3,
  [HeroStyle.SHORT_LEFT_ALIGNED]: 16 / 9,
  [HeroStyle.SHORT_CENTER_ALIGNED]: 16 / 9,
  [HeroStyle.SHORT_LEFT_ALIGNED_WITH_IMAGE]: 16 / 9,
  [HeroStyle.BIG_WITH_BOTTOM_IMAGE]: 4 / 3,
  [HeroStyle.BIG_WITH_CENTERED_IMAGE]: 4 / 3,
}

const heroQueryBuilder = hero.builder.filter('_id == $id').map((hero) => ({
  backgroundVideo: hero.fields.backgroundVideo
    ?.resolve(['_id', 'fields'])
    .use(),
  backgroundImage: hero.fields.backgroundImage
    ?.resolve(['_id', 'fields'])
    .use(),
  mobileBackgroundImage: hero.fields.mobileBackgroundImage
    ?.resolve(['_id', 'fields'])
    .use(),
  foregroundImage: hero.fields.foregroundImage
    ?.resolve(['_id', 'fields'])
    .use(),
  mobileForegroundImage: hero.fields.mobileForegroundImage
    ?.resolve(['_id', 'fields'])
    .use(),
  ctaAction: hero.fields.ctaAction?.resolve(['_id', 'fields']).use(),
  ctaActions: hero.fields.ctaActions?.resolveIn('fields').use(),
}))
const [manyHeroQuery, manyHeroType] = heroQueryBuilder.use()
const [withSubHeroesQuery, withSubHeroesType] = heroQueryBuilder
  .subquery({
    components: asResolverQuery<typeof manyHeroType>(
      'fields.components',
      manyHeroQuery,
      '@->_type == "hero"'
    ),
  })
  .first()
  .use()

const [heroSectionQuery, heroSectionType] = heroSection.builder
  .filter('_id == $id')
  .map((hero) => ({
    backgroundMedia: hero.fields.backgroundMedia
      ?.resolve(['_id', 'fields'])
      .use(),
    mobileBackgroundMedia: hero.fields.mobileBackgroundMedia
      ?.resolve(['_id', 'fields'])
      .use(),
    image: hero.fields.image?.resolve(['_id', 'fields']).use(),
    mobileImage: hero.fields.mobileImage?.resolve(['_id', 'fields']).use(),
    callToActions: hero.fields.callToActions?.resolveIn('fields').use(),
  }))
  .first()
  .use()

export const ContentfulHero: React.FunctionComponent<Props> = (props) => {
  const heroSectionReader = useData<typeof heroSectionType>(
    DataSourceType.CONTENTFUL,
    heroSectionQuery,
    'heroSectionData' in props ? { id: props.heroSectionData._id } : {},
    'heroSectionData' in props
  )
  const heroSectionReaderData = heroSectionReader()

  const heroDataReader = useData<typeof withSubHeroesType>(
    DataSourceType.CONTENTFUL,
    withSubHeroesQuery,
    'heroData' in props ? { id: props.heroData._id } : {},
    'heroData' in props
  )
  const firstHeroData = heroDataReader()

  const heroData =
    'heroData' in props && firstHeroData
      ? {
          ...firstHeroData,
          ...firstHeroData?.components?.[0],
          version: firstHeroData.fields?.version,
          fields: {
            ...firstHeroData.fields,
            ...firstHeroData?.components?.[0].fields,
          },
        }
      : null
  const heroSectionData =
    'heroSectionData' in props && heroSectionReaderData
      ? heroSectionReaderData
      : null

  const { theme } = useThemeUI()
  const layoutContext = useContext(LayoutContext)
  const transformCta = useTransformContentfulCtaAction()
  const shopifyProductId = layoutContext?.pageProduct?.shopifyProductId

  const safeProductId = shopifyProductId
    ? decodeShopifyId(shopifyProductId)
    : null

  const okendoData = useOkendoData(safeProductId ?? 'store')

  const prerelease = heroSectionData?.fields?.prerelease ?? false

  if (heroSectionData || heroData?.version === 'v2') {
    const derivedHeroStyle =
      heroData?.fields.imageAlignment === 'bottom'
        ? HeroStyle.BIG_WITH_BOTTOM_IMAGE
        : HeroStyle.SHORT_LEFT_ALIGNED_WITH_IMAGE
    const heroStyle = (heroSectionData?.fields?.style ||
      derivedHeroStyle) as HeroStyle
    const label = heroSectionData?.fields?.label || heroData?.fields.label
    const title = heroSectionData?.fields?.title || heroData?.fields?.title
    const description =
      heroSectionData?.fields?.heroDescription || heroData?.fields.subtitleRT
    const callToActions = transformCta(
      compact([
        ...(heroSectionData?.callToActions ?? []),
        ...(heroData?.ctaActions ?? []),
        heroData?.ctaAction?.fields,
      ])
    )
    const backgroundColor = (heroSectionData?.fields?.backgroundColor ||
      heroData?.fields?.backgroundColor) as BackgroundColours | undefined
    const backgroundImage =
      (heroSectionData?.backgroundMedia?.fields?.file?.contentType?.startsWith(
        'image/'
      ) &&
        transformImage(heroSectionData.backgroundMedia?.fields, {
          fullscreen: true,
          width: 1920,
          objectFit: 'cover',
          objectPosition: 'center',
        })) ||
      transformImage(heroData?.backgroundImage?.fields, {
        fullscreen: true,
        width: 1920,
        objectFit: 'cover',
        objectPosition: 'center',
      })
    const mobileBackgroundImage =
      (heroSectionData?.mobileBackgroundMedia?.fields?.file?.contentType?.startsWith(
        'image/'
      ) &&
        transformImage(heroSectionData.mobileBackgroundMedia.fields, {
          fullscreen: true,
          width: 1920,
          objectFit: 'cover',
          objectPosition: 'center',
        })) ||
      transformImage(heroData?.mobileBackgroundImage?.fields, {
        fullscreen: true,
        width: 1920,
        objectFit: 'cover',
        objectPosition: 'center',
      })
    const backgroundVideoUrl =
      (heroSectionData?.backgroundMedia?.fields?.file?.contentType?.startsWith(
        'video/'
      ) &&
        heroSectionData?.backgroundMedia?.fields?.file?.url) ||
      heroData?.backgroundVideo?.fields?.file?.url ||
      null
    const mobileBackgroundVideoUrl =
      (heroSectionData?.mobileBackgroundMedia?.fields?.file?.contentType?.startsWith(
        'video/'
      ) &&
        heroSectionData?.mobileBackgroundMedia?.fields?.file?.url) ||
      null
    const image = transformImage(
      heroSectionData?.image?.fields || heroData?.foregroundImage?.fields,
      {
        objectFit: 'contain',
        fullscreen:
          heroStyle === HeroStyle.FULL_BLEED ||
          heroStyle === HeroStyle.FULL_BLEED_LIGHT
            ? true
            : false,
        width: 1920,
        aspectRatio: imageAspectRatios[heroStyle],
        transformOptions: { fit: 'pad' },
      }
    )
    const mobileImage = transformImage(
      heroSectionData?.mobileImage?.fields ||
        heroData?.mobileForegroundImage?.fields,
      {
        objectFit: 'contain',
        fullscreen:
          heroStyle === HeroStyle.FULL_BLEED ||
          heroStyle === HeroStyle.FULL_BLEED_LIGHT
            ? true
            : false,
        width: 1920,
        aspectRatio: mobileImageAspectRatios[heroStyle],
        transformOptions: { fit: 'pad' },
      }
    )

    const mediaOverlay = (heroSectionData?.fields?.mediaOverlay ??
      'none') as MediaOverlay
    const showTrustpilot =
      heroSectionData?.fields?.showRating ??
      heroData?.fields.showTrustpilot ??
      false

    return (
      <Box>
        <Hero
          version={prerelease ? 'v2-next' : 'v2'}
          sxGrid={{
            pt: layoutContext.transparentNavbar
              ? [
                  get(theme, 'sizes.skinnyNavHeight'),
                  get(theme, 'sizes.wideNavHeight'),
                ]
              : null,
          }}
          style={heroStyle}
          label={label}
          title={title}
          description={
            !!description && (
              <RichTextContent inheritParagraphFont document={description} />
            )
          }
          callToActions={compact(
            Array.isArray(callToActions) ? callToActions : [callToActions]
          )}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage ?? undefined}
          backgroundVideoUrl={backgroundVideoUrl ?? undefined}
          mobileBackgroundImage={mobileBackgroundImage ?? undefined}
          mobileBackgroundVideoUrl={mobileBackgroundVideoUrl ?? undefined}
          image={image ?? undefined}
          mobileImage={mobileImage ?? undefined}
          starReview={
            showTrustpilot && okendoData?.stars
              ? {
                  numberOfReviews: okendoData?.totalNumberOfReviews ?? 0,
                  rating: okendoData?.stars,
                }
              : undefined
          }
          imageOverlayOpacity={mediaOverlay}
        />
      </Box>
    )
  }

  if (heroData) {
    const callToActions = transformCta(
      compact([
        heroData.ctaAction?.fields,
        ...(heroData.ctaActions ?? []),
        heroData?.ctaAction?.fields?.title && heroData?.ctaAction.fields.url
          ? {
              title: heroData?.ctaAction?.fields?.title,
              url: heroData?.ctaAction?.fields.url,
            }
          : null,
      ])
    )

    return (
      <Hero
        title={heroData.fields.title ?? ''}
        subtitle={<RichTextContent document={heroData.fields.subtitleRT} />}
        ctaActions={compact(
          Array.isArray(callToActions) ? callToActions : [callToActions]
        )}
        backgroundImage={
          transformImage(heroData.backgroundImage?.fields, {
            fullscreen: true,
            width: 1920,
          }) || undefined
        }
        foregroundImage={
          transformImage(heroData?.foregroundImage?.fields, {
            width: 1920,
          }) || undefined
        }
        showButtonDown={heroData.fields.showScrollHint}
        id="hero"
        starReview={
          heroData.fields.showTrustpilot && okendoData?.stars
            ? {
                numberOfReviews: okendoData?.totalNumberOfReviews ?? 0,
                rating: okendoData?.stars,
              }
            : undefined
        }
      />
    )
  }

  return null
}

export default ContentfulHero
