import { okendoInfo } from '@/schemas/okendo'
import { DataSourceType, useData } from './useData'

const [query, type] = okendoInfo.builder
  .filter('entityId == $entityId')
  .first()
  .use()

export function useOkendoData(entityId: string | number) {
  const reader = useData<typeof type>(DataSourceType.OKENDO, query, {
    entityId: String(entityId),
  })
  return reader()
}
