import { backgroundColors, MediaOverlay } from '@/contentful/utils/commonValues'
import { HeroStyle } from '@heights/heights-ui-components'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'

const heroSectionFields = defineObject(
  'heroSectionFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    style: {
      type: 'string',
      options: { list: Object.values(HeroStyle) },
    },
    label: { type: 'string' },
    title: { type: 'string' },
    heroDescription: { type: 'json' },
    callToActions: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'ctaAction' }] }],
    },
    backgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
    backgroundMedia: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mobileBackgroundMedia: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    image: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mobileImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    showRating: {
      type: 'boolean',
    },
    showReviewsLink: {
      type: 'boolean',
    },
    mediaOverlay: {
      type: 'string',
      options: { list: Object.values(MediaOverlay) },
    },
    prerelease: {
      type: 'boolean',
    },
  },
  [{} as JsonType, ctaAction.ctaAction, asset.asset]
)

export const heroSection = defineDocument(
  'heroSection',
  {
    fields: {
      type: 'heroSectionFields',
    },
  },
  [heroSectionFields.heroSectionFields]
)
